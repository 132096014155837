@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

html {
  height: 100%;
  overflow: hidden;
}

body {
  background: linear-gradient(to bottom, #FFF, #EEE);
  background-size: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  user-select: none;
}

h2 {
  margin: 5px 0 5px 0;
  border-bottom: 1px solid #555;
  color: #555;
  font-size: 20px;
  text-transform: uppercase;
}

button {
  display: block;
  width: 100%;
  font-size: 30px;
  text-align: center;
  color: #FFF;
  background: black;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 4px #333;
  margin: 35px auto 0 auto;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
}

.wrapper {
  width: calc(100% - 30px);
  margin: 0 auto;
  height: 35vh;
  overflow-y: scroll;
}

p {
  font-size: 18px;
  padding: 10px;
}

.App {
  max-width: 800px;
  margin: 0 auto;
}
