@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Baloo+Bhaina);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  background: linear-gradient(to bottom, #FFF, #EEE);
  background-size: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
}

h2 {
  margin: 5px 0 5px 0;
  border-bottom: 1px solid #555;
  color: #555;
  font-size: 20px;
  text-transform: uppercase;
}

button {
  display: block;
  width: 100%;
  font-size: 30px;
  text-align: center;
  color: #FFF;
  background: black;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 4px #333;
  margin: 35px auto 0 auto;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
}

.wrapper {
  width: calc(100% - 30px);
  margin: 0 auto;
  height: 35vh;
  overflow-y: scroll;
}

p {
  font-size: 18px;
  padding: 10px;
}

.App {
  max-width: 800px;
  margin: 0 auto;
}

.Entrance {
    display: block;
}

.Entrance input {
    width: 80%;
    font-size: 30px;
    text-align: center;
    color: #FFF;
    background-color: #a5a5a5;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    margin: 10px auto;
    box-sizing: border-box;
    display: block;
    font-family: 'Source Sans Pro', sans-serif;
}

.Entrance input::-webkit-input-placeholder {
    color: white;
}

.Entrance input::placeholder {
    color: white;
}

.Entrance button {
    display: block;
    width: 80%;
    font-size: 30px;
    text-align: center;
    color: #FFF;
    background: black;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 4px #333;
    margin: 10px auto;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    transition: all 0.1s;
}

.Entrance button:active {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
    box-shadow: 0 0 4px #333;
}

.Entrance .divider {
    height: 40px;
}

.Entrance .logo {
    font-size: 50px;
    font-family: 'Baloo Bhaina', sans-serif;
    color: #dedede;
    letter-spacing: -4px;
    display: flex;
    margin: 0 auto;
    position: relative;
}

.Entrance .logo .title {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 5px;
    z-index: -1;
}

.Entrance .logo .version {
    font-size: 20px;
    letter-spacing: normal;
    display: inline-block;
    margin-left: 5px;
}

.Entrance .bot-logo {
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
}

.Entrance .bot-logo a {
    display: block;
    width: 220px;
    height: 100px;
    background-image: url(https://bptrivia.com/themes/custom/bptrivia/images/logo-text.svg);
    background-size: contain;
    background-position: center 40%;
    background-repeat: no-repeat;
}

@media (max-width: 600px) {
    .Entrance .logo .bpt-logo {
        position: absolute;
        text-align: center;
        z-index: -1;
        top: 65px;
        left: calc(50% - 110px);
        width: 220px;
    }
}

.Entrance p {
    width: 80%;
    margin: 6rem auto 2rem auto;
    color: #777;
}

.Entrance p a {
    font-weight: bold;
    color: #3232be;
    text-decoration: none;
}
.Header {
    display: block;
}

.Header h1 {
    display: block;
    margin: 0;
    width: 100%;
    padding: 10px 15px;
    color: #555;
    font-size: 20px;
    text-transform: uppercase;
}

.Buzzer {
    position: fixed;
    height: 50vh;
    width: 100%;
    bottom: 0;
    left: 0;
    border-top: 5px solid #EEE;
    padding: 20px;
    box-sizing: border-box;
}

.Buzzer .buzz-button {
    background: #3697bf;
    box-shadow: 0 10px 10px #999;
    width: 100%;
    max-width: 800px;
    height: 100%;
    border-radius: 50px;
    margin: 0 auto;
    display: flex;
    justify-items: center;
    align-items: center;
    box-sizing: border-box;
    transition: all 0.1s;
    font-size: 80px;
    text-shadow: 2px 2px 2px #333;
}

.Buzzer .buzz-button.is-buzzing {
    background: #ffe500;
}

.Buzzer .buzz-button.locked {
    background: #d20c0c;
    font-size: 40px;
}

.Buzzer .buzz-button.no-clear {
    background: #d20c0c;
}

.Buzzer .buzz-button:active {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    box-shadow: 0 2px 10px #999;
}

.Buzzer .buzz-button .button-text {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #FFF;
}

.Lobby {
    display: block;
}


.Lobby .player {
    display: block;
    padding: 5px 0 5px 25px;
    background-color: #F5F5F5;
    color: #555;
    font-size: 20px;
    position: relative;
    font-family: 'Source Sans Pro', sans-serif;
    box-shadow: 1px 0px 1px #e0e0e0;
    transition: all 0.2s;
    height: 38px;
    width: 100%;
    box-sizing: border-box;
}

.Lobby .player .player-name {
    display: block;
    position: absolute;
}

.Lobby .player.active {
    color: #FFF;
}

.Lobby .room-code {
    font-weight: bold;
    color: #ec6e54;
}

.Lobby .player:before {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3697bf;
    transition: all 0.2s;
}

.Lobby .player.active:before {
    width: 100%;
}

.Lobby .player-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}
