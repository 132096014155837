.Header {
    display: block;
}

.Header h1 {
    display: block;
    margin: 0;
    width: 100%;
    padding: 10px 15px;
    color: #555;
    font-size: 20px;
    text-transform: uppercase;
}
