.Buzzer {
    position: fixed;
    height: 50vh;
    width: 100%;
    bottom: 0;
    left: 0;
    border-top: 5px solid #EEE;
    padding: 20px;
    box-sizing: border-box;
}

.Buzzer .buzz-button {
    background: #3697bf;
    box-shadow: 0 10px 10px #999;
    width: 100%;
    max-width: 800px;
    height: 100%;
    border-radius: 50px;
    margin: 0 auto;
    display: flex;
    justify-items: center;
    align-items: center;
    box-sizing: border-box;
    transition: all 0.1s;
    font-size: 80px;
    text-shadow: 2px 2px 2px #333;
}

.Buzzer .buzz-button.is-buzzing {
    background: #ffe500;
}

.Buzzer .buzz-button.locked {
    background: #d20c0c;
    font-size: 40px;
}

.Buzzer .buzz-button.no-clear {
    background: #d20c0c;
}

.Buzzer .buzz-button:active {
    transform: translate3d(0, 10px, 0);
    box-shadow: 0 2px 10px #999;
}

.Buzzer .buzz-button .button-text {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #FFF;
}
