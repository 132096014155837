@import url('https://fonts.googleapis.com/css?family=Baloo+Bhaina');

.Entrance {
    display: block;
}

.Entrance input {
    width: 80%;
    font-size: 30px;
    text-align: center;
    color: #FFF;
    background-color: #a5a5a5;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    margin: 10px auto;
    box-sizing: border-box;
    display: block;
    font-family: 'Source Sans Pro', sans-serif;
}

.Entrance input::placeholder {
    color: white;
}

.Entrance button {
    display: block;
    width: 80%;
    font-size: 30px;
    text-align: center;
    color: #FFF;
    background: black;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 4px #333;
    margin: 10px auto;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    transition: all 0.1s;
}

.Entrance button:active {
    transform: translate3d(0, 5px, 0);
    box-shadow: 0 0 4px #333;
}

.Entrance .divider {
    height: 40px;
}

.Entrance .logo {
    font-size: 50px;
    font-family: 'Baloo Bhaina', sans-serif;
    color: #dedede;
    letter-spacing: -4px;
    display: flex;
    margin: 0 auto;
    position: relative;
}

.Entrance .logo .title {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 5px;
    z-index: -1;
}

.Entrance .logo .version {
    font-size: 20px;
    letter-spacing: normal;
    display: inline-block;
    margin-left: 5px;
}

.Entrance .bot-logo {
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
}

.Entrance .bot-logo a {
    display: block;
    width: 220px;
    height: 100px;
    background-image: url(https://bptrivia.com/themes/custom/bptrivia/images/logo-text.svg);
    background-size: contain;
    background-position: center 40%;
    background-repeat: no-repeat;
}

@media (max-width: 600px) {
    .Entrance .logo .bpt-logo {
        position: absolute;
        text-align: center;
        z-index: -1;
        top: 65px;
        left: calc(50% - 110px);
        width: 220px;
    }
}

.Entrance p {
    width: 80%;
    margin: 6rem auto 2rem auto;
    color: #777;
}

.Entrance p a {
    font-weight: bold;
    color: #3232be;
    text-decoration: none;
}